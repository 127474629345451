import React, { useEffect } from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTES_PAGES_MAPPING } from '../../constants/routesPageMapping';
import { clearAllBannerMessages } from '../actions/bannerMessages';

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearAllBannerMessages());
  }, []);

  return (
    <Column
      alignmentVertical="center"
      backgroundColor="primary"
      spacingInset="xlarge"
      className="welcomeSection"
    >
      <Text id="welcomeNameGreeting" alignment="left" type="h400">
        Hello,
      </Text>
      <Row alignmentHorizontal="center" spacingInset="none none large none">
        <Text id="welcomeDescription" type="b400">
          Welcome to Internal Schedule Finder. This resource allows CSA
          candidates manager to manage candidates and schedules.Select one of
          options below to start.
        </Text>
      </Row>
      {ROUTES_PAGES_MAPPING.map((page) => (
        <Button
          id={page[0]}
          key={page}
          type="tertiary"
          onClick={() => history.push('/' + page[0])}
        >
          {page[1]}
        </Button>
      ))}
    </Column>
  );
};

export default Home;
